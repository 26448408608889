import React, { useState, useEffect, useRef } from "react";
import { getPatient, deletePatient } from "./actions/index";
import $ from "jquery";
import Button from "devextreme-react/button";
import DataGrid, {
  Button as ButtonGrid,
  Column,
  Lookup,
  LoadPanel,
  Scrolling,
  Sorting,
  FilterRow,
  ColumnFixing,
  Pager,
  Paging,
  HeaderFilter,
  Export,
} from "devextreme-react/data-grid";
import { getLookupGender } from "../../Common/actions/index";
import common from "../../../utils/common";
import DateBox from "devextreme-react/date-box";
import PatientEditPopup from "./form/index";
import PatientViewPopup from "./form/view";
import PatientMedicationPopup from "./form/medication";
import { confirm } from "devextreme/ui/dialog";
import session from "../../../utils/session";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import "./style.css";
const gridRef = React.createRef();
// const permission = session.get("role");
// debugger

function Index(props) {
  const permission = session.get("role");
  debugger;
  const inputRef = useRef();
  const [Data, setData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupVisible2, setPopupVisible2] = useState(false);
  const [popupVisible3, setPopupVisible3] = useState(false);
  const [currentPatient, setCurrentPatient] = useState({});
  const [lookupGender, setLookupGender] = useState([]);
  // const [Column, setColumn] = useState([]);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const hidePopup = () => {
    setPopupVisible(false);
    setCurrentPatient({});
  };
  const hidePopup2 = () => {
    setPopupVisible2(false);
  };
  const hidePopup3 = () => {
    setPopupVisible3(false);
  };
  const DeletePatient = (patient) => {
    deletePatient(patient.Id, () => {
      loadReport();
    });
  };
  const exportGrid = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    const dataGrid = gridRef.current.instance;
    doc.setFontSize(2);
    //#region jspdf
    // debugger
    // var dataStore = dataGrid.getDataSource();
    // var data = dataStore._store._array;
    // var keys = Object.keys(dataStore._items[0]);

    // doc.setFontSize(15);

    // const title = "My Awesome Report";
    // const headers = [keys];

    // const enddata = data.map(elt => [elt.Serial, elt.Name]);

    // let content = {
    //     startY: 50,
    //     head: headers,
    //     body: data
    // };

    // doc.text(title, marginLeft, 40);
    // doc.autoTable(content);
    // doc.save("report.pdf")
    //#endregion
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
    }).then(() => {
      doc.save("Patients.pdf");
    });
  };
  useEffect(() => {
    getLookupGender((data) => {
      setLookupGender(data);
      //   let col = [
      //     {
      //       dataField: "Serial",
      //       caption: "Sr.#",
      //       width: 200,
      //       cssClass: "text-center font-weight-bold",
      //     },
      //     {
      //       dataField: "Name",
      //       cssClass: "text-center",
      //       caption: "Patient Name",
      //     },
      //     {
      //       dataField: "MobileNumber",

      //       cssClass: "text-center",
      //       caption: "Patient Mobile Number",
      //     },
      //     {
      //       dataField: "Age",
      //       cssClass: "text-center",
      //       caption: "Age",
      //     },
      //     {
      //       dataField: "Gender",
      //       cssClass: "text-center",
      //       caption: "Gender",
      //       lookup: {
      //         dataSource: data,
      //         displayExpr: "Name",
      //         valueExpr: "Id",
      //       },
      //     },
      //     {
      //       dataField: "Cnic",
      //       cssClass: "text-center",
      //       caption: "CNIC",
      //     },
      //     {
      //       cssClass: "text-center",
      //       dataField: "DistrictName",
      //       caption: "District",
      //     },
      //     {
      //       dataField: "LocalityText",
      //       cssClass: "text-center",
      //       caption: "Tehsil",
      //     },
      //     {
      //       cssClass: "text-center",
      //       dataField: "AutoMedicinalFollowUp",
      //       caption: "Auto Medicinal Follow-Up",
      //       calculateCellValue: (rowdata) => {
      //         return `${rowdata.AutoMedicinalFollowUp}x`;
      //       },
      //     },
      //     {
      //       dataField: "ReferedToDcDtc",
      //       cssClass: "text-center",
      //       caption: "Refer to Dc/Dtc",
      //     },
      //     {
      //       caption: "Notify in IDM",
      //       cssClass: "text-center",
      //       columns: [
      //         { dataField: "Notified", caption: "Notified" },
      //         { dataField: "ReferBackTo", caption: "Refer Back To DTC" },
      //       ],
      //     },
      //     {
      //       dataField: "Remarks",
      //       cssClass: "text-center",
      //     },
      //     {
      //       dataField: "ApprovedByCCName",
      //       visible: permission.FORM_PATIENT_MEDICATION,
      //       cssClass: "text-center",
      //       caption: "Appd By Call Center",
      //     },
      //     {
      //       visible: permission.FORM_PATIENT_MEDICATION,
      //       dataField: "ApprovedByDCName",
      //       cssClass: "text-center",
      //       caption: "Appd By D.Cordinator",
      //     },
      //     {
      //       visible: permission.FORM_PATIENT_MEDICATION,
      //       dataField: "ApprovedByDTCName",
      //       cssClass: "text-center",
      //       caption: "Appd By DTC.Cordinator",
      //     },
      //     {
      //       visible: permission.FORM_PATIENT_MEDICATION,
      //       dataField: "ApprovedByOtherName",
      //       cssClass: "text-center",
      //       caption: "Appd By Other",
      //     },
      //     {
      //       dataField: "ChemistName",
      //       cssClass: "text-center",
      //       caption: "Pharmacy Name",
      //     },
      //     {
      //       dataField: "ChemistAddress",
      //       cssClass: "text-center",
      //       caption: "Pharmacy Address",
      //     },
      //     {
      //       dataField: "LicenseNumber",
      //       cssClass: "text-center",
      //       caption: "License #",
      //     },
      //     {
      //       dataField: "ChemistDistrict",
      //       cssClass: "text-center",
      //       caption: "Pharmacy District",
      //     },
      //     {
      //       dataField: "ChemistLocality",
      //       cssClass: "text-center",
      //       caption: "Pharmacy Tehsil",
      //     },
      //     {
      //       type: "buttons",
      //       caption: "Control",
      //       fixed: true,
      //       fixedPosition: "right",
      //       buttons: [
      //         {
      //           text: "delete",
      //           visible: permission.PATIENT_DELETE,
      //           // cssClass: "btn btn-warning btn-s",
      //           onClick: function (e) {
      //             var find = e.row.data;
      //             let result = confirm("<i>Are you sure?</i>", "Confirm changes");
      //             result.then((dialogResult) => {
      //               if (dialogResult) DeletePatient(find);
      //             });
      //           },
      //         },
      //         {
      //           text: "view",
      //           // cssClass: "btn btn-warning btn-s",
      //           onClick: function (e) {
      //             var find = e.row.data;
      //             setCurrentPatient(find);
      //             setPopupVisible2(true);
      //           },
      //         },
      //         {
      //           visible: permission.FORM_PATIENT,
      //           text: "edit",
      //           onClick: function (e) {
      //             var find = e.row.data;
      //             setCurrentPatient(find);
      //             setPopupVisible(true);
      //           },
      //         },
      //         {
      //           visible: permission.FORM_PATIENT_MEDICATION,
      //           text: "medication",
      //           onClick: function (e) {
      //             var find = e.row.data;
      //             setCurrentPatient(find);
      //             setPopupVisible3(true);
      //           },
      //         },
      //       ],
      //     },
      //   ];
    });
  }, []);
  const loadReport = () => {
    const grid = gridRef.current.instance;
    grid.getScrollable().scrollTo(0);
    let obj = {
      StartDate: StartDate,
      EndDate: EndDate,
    };
    inputRef.current.setAttribute("disabled", "");
    gridRef.current.instance.beginCustomLoading();
    getPatient(obj, function (data) {
      inputRef.current.removeAttribute("disabled");
      gridRef.current.instance.endCustomLoading();
      data.map((x) => {
        if (x.ReferedToDcDtc === null) x.ReferedToDcDtc = false;
        if (x.Notified === null) x.Notified = false;
        if (x.ReferBackTo === null) x.ReferBackTo = false;
      });
      setData(data);
    });
  };
  const cellTemplate = (e) => {
    return e.rowIndex + 1;
  };
  // const customizeExcelCell=(options)=> {
  //     if (options.gridCell.rowType === "data") {
  //       if (options.gridCell.column.dataField === "Serial1") {
  //         options.value = options.gridCell.data.Serial1;
  //         options.wrapTextEnabled = true;
  //       }
  //     }
  //   }
  let num = 1;
  const calculateCellValue = (e) => {
    if (e.Serial <= num) {
      num = 1;
      return num;
    } else {
      var a = num + 1;
      num = a;
      return a;
    }
  };
  const optionChanged = (e) => {
    num = 0;
  };
  return (
    <div className="container-fluid">
      <div className="row d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Report of Patients Registered</h1>
      </div>
      <div className="row">
        <form>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">From:</label>
              <DateBox
                max={new Date()}
                placeholder="From"
                onValueChanged={(e) =>
                  setStartDate(common.ConverDateToSQLFormat(e.value, "-"))
                }
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="inputPassword4">To:</label>
              <DateBox
                max={new Date()}
                placeholder="To"
                onValueChanged={(e) =>
                  setEndDate(common.ConverDateToSQLFormat(e.value, "-"))
                }
              />
            </div>
            <div className="form-group col-md-3" style={{ marginTop: "35px" }}>
              <button
                ref={inputRef}
                className="btn btn-sm btn-primary"
                onClick={loadReport}
              >
                <span className="fas fa-sm fa-file"></span>
                &nbsp; Load Report
              </button>
            </div>
            <div className="form-group col-md-3" style={{ marginTop: "35px" }}>
              <button className="btn btn-sm btn-light" onClick={exportGrid}>
                <span className="fas fa-file-pdf"></span>
                &nbsp; Export to PDF
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <DataGrid
          ref={gridRef}
          onOptionChanged={optionChanged}
          width="100%"
          height="440px"
          dataSource={Data}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}
        >
          <Column
            dataField="Serial"
            allowSorting={false}
            allowFiltering={false}
            caption="Sr.#"
            cssClass="text-center font-weight-bold"
            // cellRender={cellTemplate}
            calculateCellValue={calculateCellValue}
          ></Column>
          <Column
            dataField="Name"
            cssClass="text-center"
            caption="Patient Name"
          ></Column>
          <Column
            dataField="MobileNumber"
            cssClass="text-center"
            caption="Patient Mobile Number"
          ></Column>
          <Column dataField="Age" cssClass="text-center" caption="Age"></Column>
          <Column dataField="Gender" cssClass="text-center" caption="Gender">
            <Lookup
              dataSource={lookupGender}
              displayExpr="Name"
              valueExpr="Id"
            ></Lookup>
          </Column>
          <Column
            dataField="Cnic"
            cssClass="text-center"
            caption="CNIC"
          ></Column>
          <Column
            cssClass="text-center"
            dataField="DistrictName"
            caption="District"
          ></Column>
          <Column
            dataField="LocalityText"
            cssClass="text-center"
            caption="Tehsil"
          ></Column>
          <Column
            cssClass="text-center"
            dataField="AutoMedicinalFollowUp"
            caption="Auto Medicinal Follow-Up"
            calculateCellValue={(rowdata) => {
              return `${rowdata.AutoMedicinalFollowUp}x`;
            }}
          ></Column>
          <Column
            dataField="ReferedToDcDtc"
            cssClass="text-center"
            caption="Refer to Dc/Dtc"
          ></Column>
          <Column caption="Notify in IDM" cssClass="text-center">
            <Column dataField="Notified" caption="Notified" />
            <Column dataField="ReferBackTo" caption="Refer Back To DTC" />
          </Column>
          <Column dataField="Remarks" cssClass="text-center"></Column>
          <Column
            dataField="ApprovedByCCName"
            visible={permission.FORM_PATIENT_MEDICATION}
            cssClass="text-center"
            caption="Appd By Call Center"
          ></Column>
          <Column
            visible={permission.FORM_PATIENT_MEDICATION}
            dataField="ApprovedByDCName"
            cssClass="text-center"
            caption="Appd By D.Cordinator"
          ></Column>
          <Column
            visible={permission.FORM_PATIENT_MEDICATION}
            dataField="ApprovedByDTCName"
            cssClass="text-center"
            caption="Appd By DTC.Cordinator"
          ></Column>
          <Column
            visible={permission.FORM_PATIENT_MEDICATION}
            dataField="ApprovedByOtherName"
            cssClass="text-center"
            caption="Appd By Other"
          ></Column>
          <Column
            dataField="ChemistName"
            cssClass="text-center"
            caption="Pharmacy Name"
          ></Column>
          <Column
            dataField="ChemistAddress"
            cssClass="text-center"
            caption="Pharmacy Address"
          ></Column>
          <Column
            dataField="LicenseNumber"
            cssClass="text-center"
            caption="License #"
          ></Column>
          <Column
            dataField="ChemistDistrict"
            cssClass="text-center"
            caption="Pharmacy District"
          ></Column>
          <Column
            dataField="ChemistLocality"
            cssClass="text-center"
            caption="Pharmacy Tehsil"
          ></Column>
          <Column
            type="buttons"
            caption="Control"
            fixed={true}
            fixedPosition="right"
          >
            <ButtonGrid
              text="delete"
              visible={permission.PATIENT_DELETE}
              onClick={(e) => {
                var find = e.row.data;
                let result = confirm("<i>Are you sure?</i>", "Confirm changes");
                result.then((dialogResult) => {
                  if (dialogResult) DeletePatient(find);
                });
              }}
            />
            <ButtonGrid
              text="view"
              onClick={(e) => {
                var find = e.row.data;
                setCurrentPatient(find);
                setPopupVisible2(true);
              }}
            />
            <ButtonGrid
              text="edit"
              visible={permission.FORM_PATIENT}
              onClick={(e) => {
                var find = e.row.data;
                setCurrentPatient(find);
                setPopupVisible(true);
              }}
            />
            <ButtonGrid
              text="medication"
              visible={permission.FORM_PATIENT_MEDICATION}
              onClick={(e) => {
                var find = e.row.data;
                setCurrentPatient(find);
                setPopupVisible3(true);
              }}
            />
          </Column>
          <FilterRow visible={true} applyFilter={true} />
          <HeaderFilter visible={true} />
          <Sorting mode="multiple" />
          <Scrolling columnRenderingMode="virtual" />
          <Pager
            allowedPageSizes={[10, 20, 50, 100]}
            showPageSizeSelector={true}
            showInfo={true}
          />
          <LoadPanel enabled />
          <Paging defaultPageSize={15} />
          <Export enabled={true} />
        </DataGrid>
      </div>
      {popupVisible && (
        <PatientEditPopup
          hidePopup={hidePopup}
          currentPatient={currentPatient}
          popupVisible={popupVisible}
        />
      )}
      {popupVisible2 && (
        <PatientViewPopup
          hidePopup2={hidePopup2}
          currentPatient={currentPatient}
          popupVisible2={popupVisible2}
        />
      )}
      {popupVisible3 && (
        <PatientMedicationPopup
          hidePopup={hidePopup3}
          currentPatient={currentPatient}
          popupVisible={popupVisible3}
        />
      )}
    </div>
  );
}

export default Index;
