import React, { Component } from "react";
import { connect } from "react-redux";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { Fragment } from "react";
import SelectBox from "devextreme-react/select-box";
import TextBox from "devextreme-react/text-box";
import DateBox from "devextreme-react/date-box";
import RadioGroup from "devextreme-react/radio-group";
import CheckBox from "devextreme-react/check-box";
import NumberBox from "devextreme-react/number-box";
import ScrollView from "devextreme-react/scroll-view";
import session from "../../../../utils/session";
import "../style.css";
import common from "../../../../utils/common";
import {
  getLookupGender,
  getAllLookupPatients,
} from "../../../Common/actions/index";
import { updatePatient } from "../actions/index";
import { getLookupLocality } from "../../../Common/actions/index";
class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPatient: props.currentPatient,
      currentIDMStatusId: 2,
      popupVisible: props.popupVisible,
      lookupGender: [],
      lookupSite: [],
      lookupSiteSub: [],
      lookupSiteSubFilter: [],
      lookupPreviouslyTreated: [],
      lookupInvestigation0Month: [],
      lookupInvestigation0MonthSub: [],
      lookupInvestigationFilter: [],
      lookupRegimen: [],
      lookupDST: [],
      lookupOutcome: [],
      lookupLocality: [],
      lookupLocalityFilter: [],
      lookupDistrict: [],
      disableButton: false,
      disabledForm: false,
      disabledCommonDcDtc: false,
      disabledDc: false,
      disabledDtc: false,
      disableArea: true,
      lookupRadioGroup: [
        { id: 1, text: "Notified" },
        { id: 2, text: "Referback to DC/DTC" },
      ],
    };
    this.buttonOptions = {
      text: "Submit",
      onClick: this.customButtonClick.bind(this),
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  customButtonClick() {
    this.setState({ disableButton: true });
    var data = this.state.currentPatient;
    data.ReferedToDcDtc =
      common.empty(data.ReferedToDcDtc) || !data.ReferedToDcDtc ? null : 1;
    data.Verified = common.empty(data.Verified) || !data.Verified ? null : 1;
    data.NotifyInIdm =
      common.empty(data.NotifyInIdm) || !data.NotifyInIdm ? null : 1;
    data.Notified = common.empty(data.Notified) || !data.Notified ? null : 1;
    data.ReferBackTo =
      common.empty(data.ReferBackTo) || !data.ReferBackTo ? null : 1;
    data.ReferBackToDc =
      common.empty(data.ReferBackToDc) || !data.ReferBackToDc ? null : 1;
    data.ReferBackToDtc =
      common.empty(data.ReferBackToDtc) || !data.ReferBackToDtc ? null : 1;
    updatePatient(data.Id, data, (res) => {
      this.props.hidePopup();
      this.setState({ popupVisible: false, disableButton: false });
    });
  }
  handleInputChange = (event) => {
    debugger;
    var locality = this.state.lookupLocalityFilter;
    var changedPatientState = this.state.currentPatient;
    var filter0month = this.state.lookupInvestigationFilter;
    var subSite = this.state.lookupSiteSub;
    const component = event.component.NAME; //dxCheckBox//dxTextBox//dxDateBox/dxSelectBox//dxNumberBox//dxRadioGroup
    const name = event.element.id;
    var value =
      component === "dxDateBox"
        ? common.ConverDateToSQLFormat(event.value, "-")
        : event.value;
    if (name === "NotifyInIdm") {
      value = event.value.id;
      if (value == "1") {
        changedPatientState.Notified = true;
        changedPatientState.ReferBackTo = false;
        changedPatientState.ReferBackToRemarks = "";
      } else if (value == "2") {
        changedPatientState.Notified = false;
        changedPatientState.ReferBackTo = true;
        changedPatientState.ReferBackToRemarks = "";
      } else {
        changedPatientState.Notified = false;
        changedPatientState.ReferBackTo = false;
        changedPatientState.ReferBackToRemarks = "";
      }
    }
    if (name === "Investigation0MonthId") {
      if (value === 21) {
        changedPatientState.Investigation0MonthSubId = 0;
        filter0month = this.state.lookupInvestigation0MonthSub.filter(
          (x) => x.LookupCategoryId === "9"
        );
      } else if (value === 23) {
        changedPatientState.Investigation0MonthSubId = 0;
        filter0month = this.state.lookupInvestigation0MonthSub.filter(
          (x) => x.LookupCategoryId === "10"
        );
      } else {
        changedPatientState.Investigation0MonthSubId = 0;
        filter0month = [];
      }
    }
    if (name === "Investigation0MonthSubId") {
      value = event.value.id;
    }
    if (name === "SiteId") {
      if (value == "16") {
        changedPatientState.SiteSubId = 0;
        subSite = [];
      }
    }
    if (name === "SiteSubId") {
      value = event.value.id;
    }
    if (name === "DistrictId") {
      locality = this.state.lookupLocality.filter((x) => x.ParentId == value);
    }
    changedPatientState[name] = value;
    this.setState({
      currentPatient: changedPatientState,
      lookupInvestigationFilter: filter0month,
      lookupSiteSubFilter: subSite,
      lookupLocalityFilter: locality,
    });
  };
  componentDidMount() {
    switch (Number(session.get("user").DesignationId)) {
      case 4:
        var ApprovedByDC =
          this.props.currentPatient.ApprovedByDC !== 0 ? true : false;
        this.setState({
          disabledForm: ApprovedByDC,
          disabledCommonDcDtc: true,
          disabledDc: true,
        });
        break;
      case 5:
        var ApprovedByDTC =
          this.props.currentPatient.ApprovedByDTC !== 0 ? true : false;
        this.setState({
          disabledForm: ApprovedByDTC,
          disabledCommonDcDtc: true,
          disabledDtc: true,
        });
        break;
      case 7:
      case 1:
        this.setState({ disableArea: false });
        break;
    }
    getLookupGender((data) => {
      this.setState({ lookupGender: data });
      getAllLookupPatients((data) => {
        this.setState({
          lookupSite: data.filter((x) => x.LookupCategoryId === "6"),
          lookupSiteSub: data
            .filter((x) => x.LookupCategoryId === "14")
            .map((x) => {
              return {
                LookupCategoryId: x.LookupCategoryId,
                id: x.Id,
                text: x.Name,
              };
            }),
          lookupSiteSubFilter:
            this.state.currentPatient.SiteId == 14 ||
            this.state.currentPatient.SiteId == 15
              ? data
                  .filter((x) => x.LookupCategoryId === "14")
                  .map((x) => {
                    return {
                      LookupCategoryId: x.LookupCategoryId,
                      id: x.Id,
                      text: x.Name,
                    };
                  })
              : [],
          lookupPreviouslyTreated: data.filter(
            (x) => x.LookupCategoryId === "7"
          ),
          lookupInvestigation0Month: data.filter(
            (x) => x.LookupCategoryId === "8"
          ),
          lookupInvestigation0MonthSub: data
            .filter(
              (x) => x.LookupCategoryId === "9" || x.LookupCategoryId === "10"
            )
            .map((x) => {
              return {
                LookupCategoryId: x.LookupCategoryId,
                id: x.Id,
                text: x.Name,
              };
            }),
          lookupInvestigationFilter:
            this.state.currentPatient.Investigation0MonthId == 21
              ? data
                  .filter((x) => x.LookupCategoryId === "9")
                  .map((x) => {
                    return {
                      LookupCategoryId: x.LookupCategoryId,
                      id: x.Id,
                      text: x.Name,
                    };
                  })
              : this.state.currentPatient.Investigation0MonthId == 23
              ? data
                  .filter((x) => x.LookupCategoryId === "10")
                  .map((x) => {
                    return {
                      LookupCategoryId: x.LookupCategoryId,
                      id: x.Id,
                      text: x.Name,
                    };
                  })
              : [],
          lookupRegimen: data.filter((x) => x.LookupCategoryId === "11"),
          lookupDST: data.filter((x) => x.LookupCategoryId === "12"),
          lookupOutcome: data.filter((x) => x.LookupCategoryId === "13"),
        });
        getLookupLocality((data) => {
          const result = [];
          const map = new Map();
          for (const item of data) {
            if (!map.has(item.LocalityId)) {
              map.set(item.LocalityId, true); // set any value to Map
              result.push({
                Id: item.LocalityId,
                Name: item.LocalityName,
                ParentId: item.DistrictId,
              });
            }
          }
          const district = [];
          const map1 = new Map();
          for (const item of data) {
            if (!map1.has(item.DistrictId)) {
              map1.set(item.DistrictId, true); // set any value to Map
              district.push({
                Id: item.DistrictId,
                Name: item.DistrictName,
              });
            }
          }
          console.log(result);
          console.log(district);
          this.setState({
            lookupLocality: result,
            lookupLocalityFilter: result.filter(
              (x) => x.ParentId == this.state.currentPatient.DistrictId
            ),
            lookupDistrict: district,
          });
        });
      });
    });
  }
  render() {
    return (
      <Fragment>
        <Popup
          visible={this.state.popupVisible}
          onHiding={this.props.hidePopup}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}
          // disabled={this.state.disabledForm}
          title="Update Patient Information"
          width="90%"
          height="90%"
        >
          <ToolbarItem
            disabled={this.state.disabledForm}
            options={this.buttonOptions}
            widget="dxButton"
            // disabled={this.state.disableButton}
            location="after"
            toolbar="bottom"
          />
          <ScrollView width="100%" height="100%">
            <form>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Prescriber Name
                </label>
                <div className="col-sm-4">
                  <TextBox
                    disabled={this.state.disabledCommonDcDtc}
                    className=""
                    id="PrescriberName"
                    defaultValue={this.state.currentPatient.PrescriberName}
                    placeholder=""
                    showClearButton={true}
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Prescriber Contact No
                </label>
                <div className="col-sm-4">
                  <TextBox
                    disabled={this.state.disabledCommonDcDtc}
                    className=""
                    id="PrescriberContactNo"
                    defaultValue={this.state.currentPatient.PrescriberContactNo}
                    placeholder=""
                    showClearButton={true}
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Date of Registration
                </label>
                <div className="col-sm-4">
                  <DateBox
                    id="AddedDate"
                    disabled={this.state.disabledDtc}
                    type="date"
                    defaultValue={this.state.currentPatient.AddedDate}
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Patient Name
                </label>
                <div className="col-sm-4">
                  <TextBox
                    className=""
                    id="Name"
                    disabled={this.state.disabledDtc}
                    defaultValue={this.state.currentPatient.Name}
                    placeholder=""
                    showClearButton={true}
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Gender
                </label>
                <div className="col-sm-4">
                  <SelectBox
                    dataSource={this.state.lookupGender}
                    displayExpr="Name"
                    searchEnabled={true}
                    valueExpr="Id"
                    disabled={this.state.disabledDtc}
                    defaultValue={this.state.currentPatient.Gender}
                    id="Gender"
                    placeholder=""
                    searchMode={"contains"}
                    onValueChanged={(e) => this.handleInputChange(e)}
                    searchExpr={"Name"}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Age
                </label>
                <div className="col-sm-4">
                  <NumberBox
                    id="Age"
                    disabled={this.state.disabledDtc}
                    defaultValue={this.state.currentPatient.Age}
                    showSpinButtons={true}
                    showClearButton={true}
                    placeholder=""
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  CNIC
                </label>
                <div className="col-sm-4">
                  <TextBox
                    className=""
                    disabled={this.state.disabledDtc}
                    id="Cnic"
                    defaultValue={this.state.currentPatient.Cnic}
                    placeholder=""
                    showClearButton={true}
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Address
                </label>
                <div className="col-sm-4">
                  <TextBox
                    className=""
                    disabled={this.state.disabledDtc}
                    id="Address"
                    defaultValue={this.state.currentPatient.Address}
                    placeholder=""
                    showClearButton={true}
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Contact #
                </label>
                <div className="col-sm-4">
                  <TextBox
                    className=""
                    disabled={this.state.disabledDtc}
                    id="MobileNumber"
                    defaultValue={this.state.currentPatient.MobileNumber}
                    placeholder=""
                    showClearButton={true}
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Treatment Start Date
                </label>
                <div className="col-sm-4">
                  <DateBox
                    id="TreatmentStartDate"
                    disabled={this.state.disabledDtc}
                    defaultValue={this.state.currentPatient.TreatmentStartDate}
                    type="date"
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Site
                </label>
                <div className="col-sm-4">
                  <SelectBox
                    dataSource={this.state.lookupSite}
                    displayExpr="Name"
                    searchEnabled={true}
                    valueExpr="Id"
                    disabled={this.state.disabledDtc}
                    defaultValue={this.state.currentPatient.SiteId}
                    id="SiteId"
                    placeholder=""
                    searchMode={"contains"}
                    onValueChanged={(e) => this.handleInputChange(e)}
                    searchExpr={"Name"}
                  />
                </div>
                {!common.empty(this.state.currentPatient.SiteSubId) &&
                  !common.empty(this.state.lookupSiteSubFilter) && (
                    <Fragment>
                      <label
                        htmlFor="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Sub Site
                      </label>
                      <div className="col-sm-4">
                        <RadioGroup
                          layout="horizontal"
                          disabled={this.state.disabledDtc}
                          id="SiteSubId"
                          className=""
                          items={this.state.lookupSiteSubFilter}
                          defaultValue={this.state.lookupSiteSubFilter.find(
                            (x) => x.id == this.state.currentPatient.SiteSubId
                          )}
                          onValueChanged={(e) => this.handleInputChange(e)}
                        />
                      </div>
                    </Fragment>
                  )}
              </div>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Previosuly Treated
                </label>
                <div className="col-sm-4">
                  <SelectBox
                    dataSource={this.state.lookupPreviouslyTreated}
                    displayExpr="Name"
                    searchEnabled={true}
                    valueExpr="Id"
                    disabled={this.state.disabledDtc}
                    defaultValue={this.state.currentPatient.PreviouslyTreatedId}
                    id="PreviouslyTreatedId"
                    placeholder=""
                    searchMode={"contains"}
                    onValueChanged={(e) => this.handleInputChange(e)}
                    searchExpr={"Name"}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  H/O ATT Unknown
                </label>
                <div className="col-sm-4">
                  <TextBox
                    className=""
                    id="HistoryOfAttUnknown"
                    disabled={this.state.disabledDtc}
                    defaultValue={this.state.currentPatient.HistoryOfAttUnknown}
                    placeholder=""
                    showClearButton={true}
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Investigation "0" month
                </label>
                <div className="col-sm-4">
                  <SelectBox
                    dataSource={this.state.lookupInvestigation0Month}
                    displayExpr="Name"
                    searchEnabled={true}
                    disabled={this.state.disabledDtc}
                    valueExpr="Id"
                    defaultValue={
                      this.state.currentPatient.Investigation0MonthId
                    }
                    id="Investigation0MonthId"
                    placeholder=""
                    searchMode={"contains"}
                    onValueChanged={(e) => this.handleInputChange(e)}
                    searchExpr={"Name"}
                  />
                </div>
                {!common.empty(
                  this.state.currentPatient.Investigation0MonthId
                ) &&
                  !common.empty(this.state.lookupInvestigationFilter) && (
                    <Fragment>
                      <label
                        htmlFor="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Investigation "0" month Sub
                      </label>
                      <div className="col-sm-4">
                        <RadioGroup
                          layout="horizontal"
                          disabled={this.state.disabledDtc}
                          id="Investigation0MonthSubId"
                          className=""
                          items={this.state.lookupInvestigationFilter}
                          defaultValue={this.state.lookupInvestigationFilter.find(
                            (x) =>
                              x.id ==
                              this.state.currentPatient.Investigation0MonthSubId
                          )}
                          onValueChanged={(e) => this.handleInputChange(e)}
                        />
                      </div>
                    </Fragment>
                  )}
              </div>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Regimen
                </label>
                <div className="col-sm-4">
                  <SelectBox
                    dataSource={this.state.lookupRegimen}
                    displayExpr="Name"
                    searchEnabled={true}
                    valueExpr="Id"
                    disabled={this.state.disabledDtc}
                    defaultValue={this.state.currentPatient.RegimenId}
                    id="RegimenId"
                    placeholder=""
                    searchMode={"contains"}
                    onValueChanged={(e) => this.handleInputChange(e)}
                    searchExpr={"Name"}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  New
                </label>
                <div className="col-sm-4">
                  <TextBox
                    className=""
                    disabled={this.state.disabledDtc}
                    id="New"
                    defaultValue={this.state.currentPatient.New}
                    placeholder=""
                    showClearButton={true}
                    onValueChanged={(e) => this.handleInputChange(e)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  District
                </label>
                <div className="col-sm-4">
                  <SelectBox
                    dataSource={this.state.lookupDistrict}
                    displayExpr="Name"
                    searchEnabled={true}
                    valueExpr="Id"
                    defaultValue={this.state.currentPatient.DistrictId}
                    id="DistrictId"
                    disabled={this.state.disableArea}
                    placeholder=""
                    searchMode={"contains"}
                    onValueChanged={(e) => this.handleInputChange(e)}
                    searchExpr={"Name"}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Locality
                </label>
                <div className="col-sm-4">
                  <SelectBox
                    dataSource={this.state.lookupLocalityFilter}
                    displayExpr="Name"
                    searchEnabled={true}
                    valueExpr="Id"
                    defaultValue={this.state.currentPatient.Locality}
                    disabled={this.state.disableArea}
                    id="Locality"
                    placeholder=""
                    searchMode={"contains"}
                    onValueChanged={(e) => this.handleInputChange(e)}
                    searchExpr={"Name"}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="container-fluid">
                  <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button
                            disabled={this.state.disabledDtc}
                            style={{ color: "black" }}
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Follow up Smear Result{" "}
                            <i
                              className="fa"
                              style={{ marginLeft: "1420px" }}
                              aria-hidden="true"
                            ></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="form-group">
                            <h6 className="mb-0">
                              Follow Up Smear Results at the end of 2nd Month
                            </h6>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="staticEmail"
                              className="col-sm-1 col-form-label"
                            >
                              Result
                            </label>
                            <div className="col-sm-3">
                              <TextBox
                                className=""
                                id="FollowUp2ndMonthResult"
                                defaultValue={
                                  this.state.currentPatient
                                    .FollowUp2ndMonthResult
                                }
                                placeholder=""
                                showClearButton={true}
                                onValueChanged={(e) =>
                                  this.handleInputChange(e)
                                }
                              />
                              <small
                                id="emailHelp"
                                className="form-text text-muted"
                              >
                                AFB SM Result.
                              </small>
                            </div>
                            <label
                              htmlFor="staticEmail"
                              className="col-sm-1 col-form-label"
                            >
                              Lab No
                            </label>
                            <div className="col-sm-3">
                              <TextBox
                                className=""
                                id="FollowUp2ndMonthLab"
                                defaultValue={
                                  this.state.currentPatient.FollowUp2ndMonthLab
                                }
                                placeholder=""
                                showClearButton={true}
                                onValueChanged={(e) =>
                                  this.handleInputChange(e)
                                }
                              />
                            </div>
                            <label
                              htmlFor="staticEmail"
                              className="col-sm-1 col-form-label"
                            >
                              Date
                            </label>
                            <div className="col-sm-3">
                              <DateBox
                                id="FollowUp2ndMonthDate"
                                defaultValue={
                                  this.state.currentPatient.FollowUp2ndMonthDate
                                }
                                type="date"
                                onValueChanged={(e) =>
                                  this.handleInputChange(e)
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <h6 className="mb-0">
                              Follow Up Smear Results at the end of 5th Month
                            </h6>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="staticEmail"
                              className="col-sm-1 col-form-label"
                            >
                              Result
                            </label>
                            <div className="col-sm-3">
                              <TextBox
                                className=""
                                id="FollowUp5thMonthResult"
                                defaultValue={
                                  this.state.currentPatient
                                    .FollowUp5thMonthResult
                                }
                                placeholder=""
                                showClearButton={true}
                                onValueChanged={(e) =>
                                  this.handleInputChange(e)
                                }
                              />
                              <small
                                id="emailHelp"
                                className="form-text text-muted"
                              >
                                AFB SM Result.
                              </small>
                            </div>
                            <label
                              htmlFor="staticEmail"
                              className="col-sm-1 col-form-label"
                            >
                              Lab No
                            </label>
                            <div className="col-sm-3">
                              <TextBox
                                className=""
                                id="FollowUp5thMonthLab"
                                defaultValue={
                                  this.state.currentPatient.FollowUp5thMonthLab
                                }
                                placeholder=""
                                showClearButton={true}
                                onValueChanged={(e) =>
                                  this.handleInputChange(e)
                                }
                              />
                            </div>
                            <label
                              htmlFor="staticEmail"
                              className="col-sm-1 col-form-label"
                            >
                              Date
                            </label>
                            <div className="col-sm-3">
                              <DateBox
                                id="FollowUp5thMonthDate"
                                type="date"
                                defaultValue={
                                  this.state.currentPatient.FollowUp5thMonthDate
                                }
                                onValueChanged={(e) =>
                                  this.handleInputChange(e)
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <h6 className="mb-0">
                              Follow Up Smear Results at the end of 6th Month
                            </h6>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="staticEmail"
                              className="col-sm-1 col-form-label"
                            >
                              Result
                            </label>
                            <div className="col-sm-3">
                              <TextBox
                                className=""
                                id="FollowUp6thMonthResult"
                                defaultValue={
                                  this.state.currentPatient
                                    .FollowUp6thMonthResult
                                }
                                placeholder=""
                                showClearButton={true}
                                onValueChanged={(e) =>
                                  this.handleInputChange(e)
                                }
                              />
                              <small
                                id="emailHelp"
                                className="form-text text-muted"
                              >
                                AFB SM Result.
                              </small>
                            </div>
                            <label
                              htmlFor="staticEmail"
                              className="col-sm-1 col-form-label"
                            >
                              Lab No
                            </label>
                            <div className="col-sm-3">
                              <TextBox
                                className=""
                                id="FollowUp6thMonthLab"
                                defaultValue={
                                  this.state.currentPatient.FollowUp6thMonthLab
                                }
                                placeholder=""
                                showClearButton={true}
                                onValueChanged={(e) =>
                                  this.handleInputChange(e)
                                }
                              />
                            </div>
                            <label
                              htmlFor="staticEmail"
                              className="col-sm-1 col-form-label"
                            >
                              Date
                            </label>
                            <div className="col-sm-3">
                              <DateBox
                                id="FollowUp6thMonthDate"
                                type="date"
                                defaultValue={
                                  this.state.currentPatient.FollowUp6thMonthDate
                                }
                                onValueChanged={(e) =>
                                  this.handleInputChange(e)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div
                  className="container-fluid border rounded pt-4"
                  style={{ backgroundColor: "#F7F7F7" }}
                >
                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Other Test
                    </label>
                    <div className="col-sm-4">
                      <TextBox
                        className=""
                        disabled={this.state.disabledDtc}
                        id="OtherTest"
                        defaultValue={this.state.currentPatient.OtherTest}
                        placeholder=""
                        showClearButton={true}
                        onValueChanged={(e) => this.handleInputChange(e)}
                      />
                    </div>
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Contact Tracing
                    </label>
                    <div className="col-sm-4">
                      <TextBox
                        className=""
                        disabled={this.state.disabledDtc}
                        id="ContactTracing"
                        defaultValue={this.state.currentPatient.ContactTracing}
                        placeholder=""
                        showClearButton={true}
                        onValueChanged={(e) => this.handleInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group row ">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      DST-Result
                    </label>
                    <div className="col-sm-4">
                      <SelectBox
                        dataSource={this.state.lookupDST}
                        displayExpr="Name"
                        searchEnabled={true}
                        valueExpr="Id"
                        disabled={this.state.disabledDtc}
                        defaultValue={this.state.currentPatient.DSTId}
                        id="DSTId"
                        placeholder=""
                        searchMode={"contains"}
                        onValueChanged={(e) => this.handleInputChange(e)}
                        searchExpr={"Name"}
                      />
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group row">
                        <label
                          htmlFor="staticEmail"
                          className="col-sm-4 col-form-label"
                        >
                          Result
                        </label>
                        <div className="col-sm-8">
                          <TextBox
                            className=""
                            id="DSTResult"
                            disabled={
                              common.empty(this.state.currentPatient.DSTId) ||
                              this.state.currentPatient.DSTId == 0
                                ? true
                                : false
                            }
                            defaultValue={this.state.currentPatient.DSTResult}
                            placeholder=""
                            showClearButton={true}
                            onValueChanged={(e) => this.handleInputChange(e)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="staticEmail"
                          className="col-sm-4 col-form-label"
                        >
                          Lab No
                        </label>
                        <div className="col-sm-8">
                          <TextBox
                            className=""
                            id="DSTLab"
                            disabled={
                              common.empty(this.state.currentPatient.DSTId) ||
                              this.state.currentPatient.DSTId == 0
                                ? true
                                : false
                            }
                            defaultValue={this.state.currentPatient.DSTLab}
                            placeholder=""
                            showClearButton={true}
                            onValueChanged={(e) => this.handleInputChange(e)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="staticEmail"
                          className="col-sm-4 col-form-label"
                        >
                          Date
                        </label>
                        <div className="col-sm-8">
                          <DateBox
                            id="DSTDate"
                            type="date"
                            disabled={
                              common.empty(this.state.currentPatient.DSTId) ||
                              this.state.currentPatient.DSTId == 0
                                ? true
                                : false
                            }
                            defaultValue={this.state.currentPatient.DSTDate}
                            onValueChanged={(e) => this.handleInputChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Treatment End Date
                    </label>
                    <div className="col-sm-4">
                      <DateBox
                        id="TreatmentEndDate"
                        disabled={this.state.disabledDtc}
                        type="date"
                        defaultValue={
                          this.state.currentPatient.TreatmentEndDate
                        }
                        onValueChanged={(e) => this.handleInputChange(e)}
                      />
                    </div>
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Outcome
                    </label>
                    <div className="col-sm-4">
                      <SelectBox
                        dataSource={this.state.lookupOutcome}
                        displayExpr="Name"
                        searchEnabled={true}
                        disabled={this.state.disabledDtc}
                        valueExpr="Id"
                        defaultValue={this.state.currentPatient.OutcomeId}
                        id="OutcomeId"
                        placeholder=""
                        searchMode={"contains"}
                        onValueChanged={(e) => this.handleInputChange(e)}
                        searchExpr={"Name"}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Remarks
                    </label>
                    <div className="col-sm-10">
                      <TextBox
                        className=""
                        id="Remarks"
                        disabled={this.state.disabledDtc}
                        defaultValue={this.state.currentPatient.Remarks}
                        placeholder=""
                        showClearButton={true}
                        onValueChanged={(e) => this.handleInputChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div
                  className="container-fluid border rounded"
                  style={{ backgroundColor: "#F7F7F7" }}
                >
                  <div className="form-group row mx-2 mt-4">
                    <div className="col-sm-2">
                      <div className="form-check">
                        <CheckBox
                          disabled={this.state.disabledCommonDcDtc}
                          id="ReferedToDcDtc"
                          defaultValue={
                            this.state.currentPatient.ReferedToDcDtc
                          }
                          text="Refer to DC/DTC"
                          onValueChanged={(e) => this.handleInputChange(e)}
                        />
                      </div>
                      <div className="form-check">
                        <CheckBox
                          disabled={
                            Number(session.get("user").DesignationId) === 5 ||
                            (this.state.disabledCommonDcDtc &&
                              this.state.currentPatient.Verified)
                              ? true
                              : false
                          }
                          id="Verified"
                          defaultValue={this.state.currentPatient.Verified}
                          text="Verified"
                          onValueChanged={(e) => this.handleInputChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group row">
                        <label
                          htmlFor="staticEmail"
                          className="col-sm-4 col-form-label"
                        >
                          Notify in IDM
                        </label>
                        <div className="col-sm-8">
                          <RadioGroup
                            disabled={this.state.disabledDc}
                            id="NotifyInIdm"
                            className=""
                            items={this.state.lookupRadioGroup}
                            defaultValue={
                              this.state.currentPatient.NotifyInIdm &&
                              this.state.currentPatient.Notified
                                ? this.state.lookupRadioGroup[0]
                                : this.state.currentPatient.NotifyInIdm &&
                                  this.state.currentPatient.ReferBackTo
                                ? this.state.lookupRadioGroup[1]
                                : this.state.lookupRadioGroup[2]
                            }
                            onValueChanged={(e) => this.handleInputChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group row">
                        <label
                          htmlFor="staticEmail"
                          className="col-sm-4 col-form-label"
                        >
                          Refer Back to Remarks
                        </label>
                        <div className="col-sm-8">
                          <TextBox
                            disabled={this.state.disabledDc}
                            className=""
                            id="ReferBackToRemarks"
                            disabled={
                              !this.state.currentPatient.ReferBackTo
                                ? true
                                : false
                            }
                            value={this.state.currentPatient.ReferBackToRemarks}
                            defaultValue={
                              this.state.currentPatient.ReferBackToRemarks
                            }
                            placeholder=""
                            showClearButton={true}
                            onValueChanged={(e) => this.handleInputChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ScrollView>
        </Popup>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  patient: state.patient.data,
});
export default connect(mapStateToProps)(index);
