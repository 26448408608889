import React, { Component } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Fragment } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';
import RadioGroup from 'devextreme-react/radio-group';
import CheckBox from 'devextreme-react/check-box';
import NumberBox from 'devextreme-react/number-box';
import common from '../../../../utils/common';
import '../style.css';
import { getPatientPerscription } from '../actions/index';
import { getLookupGender, getAllLookupPatients } from '../../../Common/actions/index';
import { getLookupLocality } from '../../../Common/actions/index';

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPatient: props.currentPatient,
            popupVisible: props.popupVisible2,
            imagesData: [],
            currentIDMStatusId: 2,
            lookupGender: [],
            lookupSite: [],
            lookupSiteSub: [],
            lookupSiteSubFilter: [],
            lookupPreviouslyTreated: [],
            lookupInvestigation0Month: [],
            lookupInvestigation0MonthSub: [],
            lookupInvestigationFilter: [],
            lookupRegimen: [],
            lookupDST: [],
            lookupOutcome: [],
            lookupLocality: [],
            lookupLocalityFilter: [],
            lookupDistrict: [],
            disabledForm: false,
            disabledCommonDcDtc: false,
            disabledDc: false,
            lookupRadioGroup: [{ id: 1, text: 'Notified' }, { id: 2, text: 'Referback to DC/DTC' }],

        };
        this.showModel = this.showModel.bind(this);
        this.hideModel = this.hideModel.bind(this);

    }
    showModel(id) {
        var modal = document.getElementById("myModal");
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        modal.style.display = "block";
        modalImg.src = id.target.src;
        captionText.innerHTML = id.target.alt;
    }
    hideModel(id) {
        var modal = document.getElementById("myModal");
        modal.style.display = "none";
    }
    componentDidMount() {
        getPatientPerscription(this.state.currentPatient.Id, (data) => {
            this.setState({ imagesData: data })
        })
        getLookupGender((data) => {
            this.setState({ lookupGender: data })
            getAllLookupPatients((data) => {
                this.setState({
                    lookupSite: data.filter(x => x.LookupCategoryId === "6"),
                    lookupSiteSub: data.filter(x => x.LookupCategoryId === "14").map(x => {
                        return {
                            LookupCategoryId: x.LookupCategoryId,
                            id: x.Id,
                            text: x.Name
                        }
                    }),
                    lookupSiteSubFilter: (this.state.currentPatient.SiteId == 14 || this.state.currentPatient.SiteId == 15) ? data.filter(x => x.LookupCategoryId === "14").map(x => {
                        return {
                            LookupCategoryId: x.LookupCategoryId,
                            id: x.Id,
                            text: x.Name
                        }
                    }) : [],
                    lookupPreviouslyTreated: data.filter(x => x.LookupCategoryId === "7"),
                    lookupInvestigation0Month: data.filter(x => x.LookupCategoryId === "8"),
                    lookupInvestigation0MonthSub: data.filter(x => x.LookupCategoryId === "9" || x.LookupCategoryId === "10").map(x => {
                        return {
                            LookupCategoryId: x.LookupCategoryId,
                            id: x.Id,
                            text: x.Name
                        }
                    }),
                    lookupInvestigationFilter: this.state.currentPatient.Investigation0MonthId == 21 ? data.filter(x => x.LookupCategoryId === "9").map(x => {
                        return {
                            LookupCategoryId: x.LookupCategoryId,
                            id: x.Id,
                            text: x.Name
                        }
                    }) : this.state.currentPatient.Investigation0MonthId == 23 ? data.filter(x => x.LookupCategoryId === "10").map(x => {
                        return {
                            LookupCategoryId: x.LookupCategoryId,
                            id: x.Id,
                            text: x.Name
                        }
                    }) : [],
                    lookupRegimen: data.filter(x => x.LookupCategoryId === "11"),
                    lookupDST: data.filter(x => x.LookupCategoryId === "12"),
                    lookupOutcome: data.filter(x => x.LookupCategoryId === "13"),
                })
                getLookupLocality((data) => {

                    const result = [];
                    const map = new Map();
                    for (const item of data) {
                        if (!map.has(item.LocalityId)) {
                            map.set(item.LocalityId, true);    // set any value to Map
                            result.push({
                                Id: item.LocalityId,
                                Name: item.LocalityName,
                                ParentId: item.DistrictId
                            });
                        }
                    }
                    const district = [];
                    const map1 = new Map();
                    for (const item of data) {
                        if (!map1.has(item.DistrictId)) {
                            map1.set(item.DistrictId, true);    // set any value to Map
                            district.push({
                                Id: item.DistrictId,
                                Name: item.DistrictName
                            });
                        }
                    }
                    console.log(result);
                    console.log(district);
                    this.setState({ lookupLocality: result, lookupLocalityFilter: result.filter(x => x.ParentId == this.state.currentPatient.DistrictId), lookupDistrict: district });
                })
            })
        })
    }
    render() {
        return (
            <Fragment>
                <Popup
                    visible={this.state.popupVisible}
                    onHiding={this.props.hidePopup2}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Patient Information"//{(`Perscription list of ${this.state.currentPatient.Name}`)}
                    width='75%'
                    height='75%'>
                    <ScrollView width='100%' height='100%'>
                        <h3>Patient Perscription List</h3>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center font-weight-bold">Sr.#</th>
                                    <th>Patient Name</th>
                                    <th>Date</th>
                                    <th className="text-right">Prescription Image</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.imagesData.map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="text-center font-weight-bold">{i + 1}</td>
                                                <td>{this.state.currentPatient.Name}</td>
                                                <td>{item.Date}</td>
                                                <td className="text-right" style={{ cursor: 'pointer' }}><img id={i + "image"} onClick={this.showModel} className="w-3 shadow-1-strong rounded" width='50px' height='50px' src={item.Image} alt={item.PatientId} /></td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                        <h3>Patient Other Info</h3>
                        <form>
                            <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Prescriber Name</label>
                                <div className="col-sm-4">
                                    <TextBox disabled={true} className="" id="PrescriberName" defaultValue={this.state.currentPatient.PrescriberName} placeholder="" showClearButton={true} />
                                </div>
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Prescriber Contact No</label>
                                <div className="col-sm-4">
                                    <TextBox disabled={true} className="" id="PrescriberContactNo" defaultValue={this.state.currentPatient.PrescriberContactNo} placeholder="" showClearButton={true} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Date of Registration</label>
                                <div className="col-sm-4">
                                    <DateBox id="AddedDate" disabled={true} type="date" defaultValue={this.state.currentPatient.AddedDate} />
                                </div>
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Patient Name</label>
                                <div className="col-sm-4">
                                    <TextBox className="" disabled={true} id="Name" defaultValue={this.state.currentPatient.Name} placeholder="" showClearButton={true} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Gender</label>
                                <div className="col-sm-4">
                                    <SelectBox dataSource={this.state.lookupGender}
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        valueExpr="Id"
                                        disabled={true} defaultValue={this.state.currentPatient.Gender}
                                        id="Gender"
                                        placeholder=''
                                        searchMode={'contains'}

                                        searchExpr={'Name'} />
                                </div>
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Age</label>
                                <div className="col-sm-4">
                                    <NumberBox id="Age" disabled={true} defaultValue={this.state.currentPatient.Age} showSpinButtons={true} showClearButton={true} placeholder="" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">CNIC</label>
                                <div className="col-sm-4">
                                    <TextBox className="" id="Cnic" disabled={true} defaultValue={this.state.currentPatient.Cnic} placeholder="" showClearButton={true} />
                                </div>
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Address</label>
                                <div className="col-sm-4">
                                    <TextBox className="" id="Address" disabled={true} defaultValue={this.state.currentPatient.Address} placeholder="" showClearButton={true} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Contact #</label>
                                <div className="col-sm-4">
                                    <TextBox className="" disabled={true} id="MobileNumber" defaultValue={this.state.currentPatient.MobileNumber} placeholder="" showClearButton={true} />
                                </div>
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Treatment Start Date</label>
                                <div className="col-sm-4">
                                    <DateBox id="TreatmentStartDate" disabled={true} defaultValue={this.state.currentPatient.TreatmentStartDate} type="date" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Site</label>
                                <div className="col-sm-4">
                                    <SelectBox dataSource={this.state.lookupSite}
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        valueExpr="Id"
                                        defaultValue={this.state.currentPatient.SiteId}
                                        id="SiteId"
                                        placeholder=''
                                        searchMode={'contains'}
                                        disabled={true}
                                        searchExpr={'Name'} />
                                </div>
                                {(!common.empty(this.state.currentPatient.SiteSubId) && !common.empty(this.state.lookupSiteSubFilter)) &&
                                    <Fragment>
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Sub Site</label>
                                        <div className="col-sm-4">
                                            <RadioGroup disabled={true} layout="horizontal" id="SiteSubId" className='' items={this.state.lookupSiteSubFilter} defaultValue={this.state.lookupSiteSubFilter.find(x => x.id == this.state.currentPatient.SiteSubId)} onValueChanged={e => this.handleInputChange(e)} />
                                        </div>
                                    </Fragment>}

                            </div>
                            <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Previosuly Treated</label>
                                <div className="col-sm-4">
                                    <SelectBox dataSource={this.state.lookupPreviouslyTreated}
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        disabled={true} valueExpr="Id"
                                        defaultValue={this.state.currentPatient.PreviouslyTreatedId}
                                        id="PreviouslyTreatedId"
                                        placeholder=''
                                        searchMode={'contains'}

                                        searchExpr={'Name'} />
                                </div>
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">H/O ATT Unknown</label>
                                <div className="col-sm-4">
                                    <TextBox className="" disabled={true} id="HistoryOfAttUnknown" defaultValue={this.state.currentPatient.HistoryOfAttUnknown} placeholder="" showClearButton={true} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Investigation "0" month</label>
                                <div className="col-sm-4">
                                    <SelectBox dataSource={this.state.lookupInvestigation0Month}
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        valueExpr="Id" disabled={true}
                                        defaultValue={this.state.currentPatient.Investigation0MonthId}
                                        id="Investigation0MonthId"
                                        placeholder=''
                                        searchMode={'contains'}

                                        searchExpr={'Name'} />
                                </div>
                                {(!common.empty(this.state.currentPatient.Investigation0MonthId) && !common.empty(this.state.lookupInvestigationFilter)) &&
                                    <Fragment>
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Investigation "0" month Sub</label>
                                        <div className="col-sm-4">
                                            <RadioGroup disabled={true} layout="horizontal" id="Investigation0MonthSubId" className='' items={this.state.lookupInvestigationFilter} defaultValue={this.state.lookupInvestigationFilter.find(x => x.id == this.state.currentPatient.Investigation0MonthSubId)} />
                                        </div>
                                    </Fragment>}
                            </div>
                            <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Regimen</label>
                                <div className="col-sm-4">
                                    <SelectBox dataSource={this.state.lookupRegimen}
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        valueExpr="Id"
                                        defaultValue={this.state.currentPatient.RegimenId}
                                        disabled={true} id="RegimenId"
                                        placeholder=''
                                        searchMode={'contains'}

                                        searchExpr={'Name'} />
                                </div>
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">New</label>
                                <div className="col-sm-4">
                                    <TextBox className="" disabled={true} id="New" defaultValue={this.state.currentPatient.New} placeholder="" showClearButton={true} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">District</label>
                                <div className="col-sm-4">
                                    <SelectBox dataSource={this.state.lookupDistrict}
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        valueExpr="Id"
                                        defaultValue={this.state.currentPatient.DistrictId}
                                        disabled={true}  id="DistrictId"
                                        placeholder=''
                                        searchMode={'contains'}
                                        onValueChanged={e => this.handleInputChange(e)}
                                        searchExpr={'Name'} />
                                </div>
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Locality</label>
                                <div className="col-sm-4">
                                    <SelectBox dataSource={this.state.lookupLocalityFilter}
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        valueExpr="Id"
                                        disabled={true} defaultValue={this.state.currentPatient.Locality}
                                        id="Locality"
                                        placeholder=''
                                        searchMode={'contains'}
                                        onValueChanged={e => this.handleInputChange(e)}
                                        searchExpr={'Name'} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="container-fluid" >
                                    <div id="accordion">
                                        <div className="card">
                                            <div className="card-header" id="headingThree">
                                                <h5 className="mb-0">
                                                    <button style={{ color: "black" }} className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Follow up Smear Result <i className="fa" style={{ marginLeft: '1420px' }} aria-hidden="true"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <h6 className="mb-0">Follow Up Smear Results at the end of 2nd Month</h6>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="staticEmail" className="col-sm-1 col-form-label">Result</label>
                                                        <div className="col-sm-3">
                                                            <TextBox disabled={true} className="" id="FollowUp2ndMonthResult" defaultValue={this.state.currentPatient.FollowUp2ndMonthResult} placeholder="" showClearButton={true} />
                                                            <small id="emailHelp" className="form-text text-muted">AFB SM Result.</small>

                                                        </div>
                                                        <label htmlFor="staticEmail" className="col-sm-1 col-form-label">Lab No</label>
                                                        <div className="col-sm-3">
                                                            <TextBox disabled={true} className="" id="FollowUp2ndMonthLab" defaultValue={this.state.currentPatient.FollowUp2ndMonthLab} placeholder="" showClearButton={true} />
                                                        </div>
                                                        <label htmlFor="staticEmail" className="col-sm-1 col-form-label">Date</label>
                                                        <div className="col-sm-3">
                                                            <DateBox disabled={true} id="FollowUp2ndMonthDate" defaultValue={this.state.currentPatient.FollowUp2ndMonthDate} type="date" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <h6 className="mb-0">Follow Up Smear Results at the end of 5th Month</h6>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="staticEmail" className="col-sm-1 col-form-label">Result</label>
                                                        <div className="col-sm-3">
                                                            <TextBox disabled={true} className="" id="FollowUp5thMonthResult" defaultValue={this.state.currentPatient.FollowUp5thMonthResult} placeholder="" showClearButton={true} />
                                                            <small id="emailHelp" className="form-text text-muted">AFB SM Result.</small>
                                                        </div>
                                                        <label htmlFor="staticEmail" className="col-sm-1 col-form-label">Lab No</label>
                                                        <div className="col-sm-3">
                                                            <TextBox disabled={true} className="" id="FollowUp5thMonthLab" defaultValue={this.state.currentPatient.FollowUp5thMonthLab} placeholder="" showClearButton={true} />
                                                        </div>
                                                        <label htmlFor="staticEmail" className="col-sm-1 col-form-label">Date</label>
                                                        <div className="col-sm-3">
                                                            <DateBox disabled={true} id="FollowUp5thMonthDate" type="date" defaultValue={this.state.currentPatient.FollowUp5thMonthDate} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <h6 className="mb-0">Follow Up Smear Results at the end of 6th Month</h6>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="staticEmail" className="col-sm-1 col-form-label">Result</label>
                                                        <div className="col-sm-3">
                                                            <TextBox disabled={true} className="" id="FollowUp6thMonthResult" defaultValue={this.state.currentPatient.FollowUp6thMonthResult} placeholder="" showClearButton={true} />
                                                            <small id="emailHelp" className="form-text text-muted">AFB SM Result.</small>
                                                        </div>
                                                        <label htmlFor="staticEmail" className="col-sm-1 col-form-label">Lab No</label>
                                                        <div className="col-sm-3">
                                                            <TextBox disabled={true} className="" id="FollowUp6thMonthLab" defaultValue={this.state.currentPatient.FollowUp6thMonthLab} placeholder="" showClearButton={true} />
                                                        </div>
                                                        <label htmlFor="staticEmail" className="col-sm-1 col-form-label">Date</label>
                                                        <div className="col-sm-3">
                                                            <DateBox disabled={true} id="FollowUp6thMonthDate" type="date" defaultValue={this.state.currentPatient.FollowUp6thMonthDate} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="container-fluid border rounded pt-4" style={{ backgroundColor: "#F7F7F7" }}>
                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Other Test</label>
                                        <div className="col-sm-4">
                                            <TextBox className="" disabled={true} id="OtherTest" defaultValue={this.state.currentPatient.OtherTest} placeholder="" showClearButton={true} />
                                        </div>
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Contact Tracing</label>
                                        <div className="col-sm-4">
                                            <TextBox className="" disabled={true} id="ContactTracing" defaultValue={this.state.currentPatient.ContactTracing} placeholder="" showClearButton={true} />
                                        </div>
                                    </div>
                                    <div className="form-group row ">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">DST-Result</label>
                                        <div className="col-sm-4">
                                            <SelectBox dataSource={this.state.lookupDST}
                                                displayExpr="Name"
                                                searchEnabled={true}
                                                valueExpr="Id"
                                                defaultValue={this.state.currentPatient.DSTId}
                                                disabled={true} id="DSTId"
                                                placeholder=''
                                                searchMode={'contains'}

                                                searchExpr={'Name'} />
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Result</label>
                                                <div className="col-sm-8">
                                                    <TextBox className="" disabled={true} id="DSTResult" defaultValue={this.state.currentPatient.DSTResult} placeholder="" showClearButton={true} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Lab No</label>
                                                <div className="col-sm-8">
                                                    <TextBox className="" disabled={true} id="DSTLab" defaultValue={this.state.currentPatient.DSTLab} placeholder="" showClearButton={true} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Date</label>
                                                <div className="col-sm-8">
                                                    <DateBox id="DSTDate" disabled={true} type="date" defaultValue={this.state.currentPatient.DSTDate} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Treatment End Date</label>
                                        <div className="col-sm-4">
                                            <DateBox id="TreatmentEndDate" disabled={true} type="date" defaultValue={this.state.currentPatient.TreatmentEndDate} />
                                        </div>
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Outcome</label>
                                        <div className="col-sm-4">
                                            <SelectBox dataSource={this.state.lookupOutcome}
                                                displayExpr="Name"
                                                searchEnabled={true}
                                                valueExpr="Id"
                                                disabled={true} defaultValue={this.state.currentPatient.OutcomeId}
                                                id="OutcomeId"
                                                placeholder=''
                                                searchMode={'contains'}

                                                searchExpr={'Name'} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Remarks</label>
                                        <div className="col-sm-10">
                                            <TextBox className="" disabled={true} id="Remarks" defaultValue={this.state.currentPatient.Remarks} placeholder="" showClearButton={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="container-fluid border rounded" style={{ backgroundColor: "#F7F7F7" }}>
                                    <div className="form-group row mx-2 mt-4">
                                        <div className="col-sm-2">
                                            <div className="form-check">
                                                <CheckBox disabled={true} id="ReferedToDcDtc" defaultValue={this.state.currentPatient.ReferedToDcDtc} text="Refer to DC/DTC" />
                                            </div>
                                            <div className="form-check">
                                                <CheckBox disabled={true} id="Verified" defaultValue={this.state.currentPatient.Verified} text="Verified" />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Notify in IDM</label>
                                                <div className="col-sm-8">
                                                    <RadioGroup disabled={true} disabled={true} id="NotifyInIdm" className='' items={this.state.lookupRadioGroup} defaultValue={(this.state.currentPatient.NotifyInIdm) && (this.state.currentPatient.Notified) ? this.state.lookupRadioGroup[0] : (this.state.currentPatient.NotifyInIdm) && (this.state.currentPatient.ReferBackTo) ? this.state.lookupRadioGroup[1] : this.state.lookupRadioGroup[2]} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Refer Back to Remarks</label>
                                                <div className="col-sm-8">
                                                    <TextBox disabled={true} className="" id="ReferBackToRemarks" disabled={(!this.state.currentPatient.ReferBackTo) ? true : false} value={this.state.currentPatient.ReferBackToRemarks} defaultValue={this.state.currentPatient.ReferBackToRemarks} placeholder="" showClearButton={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div id="myModal" className="modal-local">
                            <span className="close" onClick={this.hideModel}>&times;</span>

                            <img className="modal-content-local" id="img01" />

                            <div id="caption"></div>
                        </div>
                    </ScrollView>
                </Popup>

            </Fragment>
        );
    }
}

export default index;