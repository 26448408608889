import React from 'react';
import { Fragment } from 'react';

function Footer(props) {
    return (
        <Fragment>
            <footer className="system-footer">
                <div className="left-section">
                    <img src={process.env.PUBLIC_URL + '/assets_2/images/stoptb.png'} alt="" />
                    <img src={process.env.PUBLIC_URL + '/assets_2/images/dopasi.png'} alt="" />
                </div>
                <div className="right-section">
                    <img src={process.env.PUBLIC_URL + '/assets_2/images/health.png'} alt="" />
                    <img src={process.env.PUBLIC_URL + '/assets_2/images/together.png'} alt="" />
                    <img src={process.env.PUBLIC_URL + '/assets_2/images/punjabgov.png'} alt="" />
                </div>
            </footer>
        </Fragment>
    );
}

export default Footer;